import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query'
import { formatDate } from '../../../../lib/Dates'
import {
  Accounting,
  Budget,
  Sheet,
  Spend,
  SpendCreate,
  SpendUpdate,
} from '../../../../lib/Types'
import * as API from '../API/SpendAPI'
import { AccountingType } from '../Enums/AccountingType'
import { SheetType } from '../Enums/SheetType'
import { SpendType } from '../Enums/SpendType'

const getQueryKey = (
  sheet: Sheet,
  filters?: API.FetchSpendsFilter,
  type: SpendType = SpendType.EXPENSE,
) => {
  const queryKey = [
    sheet.sheet_type,
    sheet.id,
    type === SpendType.EXPENSE ? 'expenses' : 'incomes',
  ]

  if (filters) {
    if (typeof filters.isExpense !== 'undefined') {
      queryKey.push(filters.isExpense ? 'outlay' : '')
    }

    if (filters.nonPaidExpensesOnly) {
      queryKey.push('non-paid')
    }

    if (filters.from && filters.to) {
      queryKey.push(`from:${formatDate(filters.from, 'mm-dd-yyyy')}`)
      queryKey.push(`to:${formatDate(filters.to, 'mm-dd-yyyy')}`)
    }
  }

  return queryKey
}

const getDatesForYear = (accountingDate: Date): { from: Date; to: Date } => {
  const from = new Date(accountingDate)
  const to = new Date(accountingDate)
  from.setMonth(0)
  from.setDate(1)
  to.setFullYear(from.getFullYear())
  to.setMonth(11)
  to.setDate(31)

  return { from, to }
}

const getDatesForMonth = (accountingDate: Date): { from: Date; to: Date } => {
  const from = new Date(accountingDate)
  from.setDate(1)

  const to = new Date()
  to.setFullYear(from.getFullYear())
  if (from.getMonth() === 11) {
    to.setMonth(0)
    to.setFullYear(to.getFullYear() + 1)
  } else {
    // Temporary set the date to 1 (first day of the month) to avoid end-of-month issues:
    // If it's the last day of Janary (day 31, month = 0) and we try to set the month of "to" to 1 (from month + 1)
    // the date object would try to set the date object to the 31st of february, which doesn't exists and would
    // instead actually set the date to 3rd of march.
    to.setDate(1)
    to.setMonth(from.getMonth() + 1)
  }
  // Setting date to 0, set the date to the last day of last month
  to.setDate(0)

  return { from, to }
}

const getDatesForWeek = (accountingDate: Date): { from: Date; to: Date } => {
  const from = new Date(accountingDate)

  // Find the first day of the week from the date.
  const dayFrom = from.getDay()
  const diffFrom = from.getDate() - dayFrom + (dayFrom === 0 ? -6 : 1) // adjust when day is Sunday
  from.setDate(diffFrom)
  // Find the last day of the week from the date.
  let to = new Date(from)
  to.setDate(to.getDate() + 6) // Add 6 to get the last day of the week

  return { from, to }
}

const getQueryKeyForSpend = (
  sheet: Sheet,
  spend: Spend | SpendCreate | SpendUpdate,
) => {
  if (sheet.sheet_type === SheetType.ACCOUNTING) {
    const accounting = sheet as Accounting
    const accountingDate = spend.accounting_date
      ? new Date(spend.accounting_date)
      : new Date()

    switch (accounting.type) {
      case AccountingType.YEAR:
        return getQueryKey(sheet, getDatesForYear(new Date(accountingDate)))
      case AccountingType.MONTH:
        return getQueryKey(sheet, getDatesForMonth(new Date(accountingDate)))
      case AccountingType.WEEK:
        return getQueryKey(sheet, getDatesForWeek(new Date(accountingDate)))
      default:
        const from = new Date(accounting.created_date)
        const to = new Date()
        to.setFullYear(to.getFullYear() + 10)
        return getQueryKey(
          sheet,
          {
            from,
            to,
          },
          spend.type,
        )
    }
  }

  return getQueryKey(sheet, {}, spend.type)
}
export const useIncomes = (
  sheet: Sheet,
  filters: API.FetchSpendsFilter = {},
): UseQueryResult<Spend[]> => {
  return useQuery({
    queryKey: getQueryKey(sheet, filters, SpendType.INCOME),
    queryFn: () => API.getIncomes(sheet, filters),
    staleTime: Infinity,
  })
}

export const useExpenses = (
  sheet: Sheet,
  filters: API.FetchSpendsFilter = {},
): UseQueryResult<Spend[]> => {
  return useQuery({
    queryKey: getQueryKey(sheet, filters, SpendType.EXPENSE),
    queryFn: () => API.getExpenses(sheet, filters),
    staleTime: Infinity,
  })
}

export const useAddSpend = (sheet: Sheet) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (spendToCreate: SpendCreate) =>
      API.addItem(sheet.sheet_type, sheet.id, spendToCreate),
    onSuccess: (spendCreated: Spend) => {
      const queryKey = getQueryKeyForSpend(sheet, spendCreated)

      queryClient.cancelQueries({ queryKey })

      queryClient.setQueryData(queryKey, (current: Spend[]) => {
        return [spendCreated, ...current]
      })

      // When a spend is added, the amounts for the sheet need to be updated.
      // This is calculated on the BE so invalidate the cache to fetch the updated.
      const amountsQueryKey =
        sheet.sheet_type === SheetType.BUDGET
          ? ['budgets', sheet.id, 'amounts']
          : ['accountings', sheet.id, 'amounts']
      queryClient.invalidateQueries({ queryKey: amountsQueryKey })

      // When a spend is added, the balances for the accounting need to be updated.
      if (sheet.sheet_type === SheetType.ACCOUNTING) {
        queryClient.invalidateQueries({
          queryKey: ['accountings', sheet.id, 'balance'],
        })
        queryClient.invalidateQueries({
          queryKey: ['accountings', sheet.id, 'balances'],
        })
      }

      // Reset the categories for the related reconciliation accounting,
      // to ensure the new spend is added as a category.
      if (sheet.sheet_type === SheetType.BUDGET) {
        if ((sheet as Budget).reconciliation_accounting_id) {
          queryClient.invalidateQueries({
            queryKey: [
              'accountings',
              (sheet as Budget).reconciliation_accounting_id,
              'categories',
            ],
          })
        }
      }
    },
  })
}

export const useUpdateSpend = (sheet: Sheet) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (spendToUpdate: SpendUpdate) => API.updateItem(spendToUpdate),
    // It's not possible to use an optimistic update because the amount for the
    // spend is added to the array in the BE.
    onSuccess: (spendUpdated: SpendUpdate) => {
      const queryKey = getQueryKey(sheet, {}, spendUpdated.type)

      // Spends are cache in groups depending on their accounting date, which makes it impossible
      // to know which group to update, if the accounting date was updated. And since it's impossible to
      // if the accounting date was updated, we have to invalidate all grouped spend caches.
      queryClient.invalidateQueries({ queryKey })

      // When a spend is updated, the amounts for the sheet needs to be updated.
      // This is calculated on the BE so invalidate the cache to fetch the updated.
      const amountsQueryKey = spendUpdated.budget_id
        ? ['budgets', spendUpdated.budget_id, 'amounts']
        : ['accountings', spendUpdated.accounting_id, 'amounts']

      queryClient.invalidateQueries({ queryKey: amountsQueryKey })

      // When a spend is added, the balances for the accounting need to be updated.
      if (sheet.sheet_type === SheetType.ACCOUNTING) {
        queryClient.invalidateQueries({
          queryKey: ['accountings', sheet.id, 'balance'],
        })
        queryClient.invalidateQueries({
          queryKey: ['accountings', sheet.id, 'balances'],
        })
      }

      // Reset the categories for the related reconciliation accounting,
      // to ensure the new spend is added as a category.
      if (sheet.sheet_type === SheetType.BUDGET) {
        if ((sheet as Budget).reconciliation_accounting_id) {
          queryClient.invalidateQueries({
            queryKey: [
              'accountings',
              (sheet as Budget).reconciliation_accounting_id,
              'categories',
            ],
          })
        }
      }
    },
  })
}

export const useDeleteSpend = (
  sheet: Sheet,
  type: SpendType = SpendType.EXPENSE,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (spend: Spend) => API.deleteItem(spend),
    onMutate: (spendToDelete: Spend): Spend[] => {
      const queryKey = getQueryKeyForSpend(sheet, spendToDelete)

      queryClient.cancelQueries({ queryKey })

      const previousState = queryClient.getQueryData(queryKey) as Spend[]

      const newSpends = previousState.filter(
        (spend) =>
          spend.id !== spendToDelete.id && spend.parent_id !== spendToDelete.id,
      )

      queryClient.setQueryData(queryKey, newSpends)

      return previousState
    },
    onError: (
      err,
      spendToDelete: Spend,
      previousState: Spend[] | undefined,
    ) => {
      if (previousState) {
        const queryKey = getQueryKey(sheet, {}, type)

        queryClient.setQueryData(queryKey, previousState)
      }

      console.error(err)
    },
    onSuccess: (data, spend: Spend) => {
      // When a spend is deleted, the amounts for the sheet need to be updated.
      // This is calculated on the BE so invalidate the cache to fetch the updated.
      const queryKey = spend.budget_id
        ? ['budgets', spend.budget_id, 'amounts']
        : ['accountings', spend.accounting_id, 'amounts']

      queryClient.invalidateQueries({ queryKey })

      // When a spend is added, the balances for the accounting need to be updated.
      if (sheet.sheet_type === SheetType.ACCOUNTING) {
        queryClient.invalidateQueries({
          queryKey: ['accountings', sheet.id, 'balance'],
        })
        queryClient.invalidateQueries({
          queryKey: ['accountings', sheet.id, 'balances'],
        })
      }
    },
  })
}
